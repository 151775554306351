import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { VueHammer } from 'vue2-hammer';
import VTooltip from 'v-tooltip'

import App from '@/App';

import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import 'vue-select/dist/vue-select.css';

import Gate from '@/gate';

// API Calls
import '@/http/requests';

// Echo - Sockets
import '@/echo';

// Theme Configurations
import '../themeConfig';

// Globally Registered Components
import '@/globalComponents';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from '@/router';

// Vuex Store
import store from '@/store/store';

// Filters
import '@/filters/filters';

// Global Mixins
import '@/mixins/globalMixin';

Vue.use(Vuesax);

// Gate
Vue.prototype.$gate = new Gate();

// VeeValidate
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
Vue.use(VueHammer);

// Vue tooltip
Vue.use(VTooltip);

// Feather font icon
require('./assets/css/iconfont.css');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
