/* eslint-disable */
export default {
  pages: {
    key: 'title',
    data: [
      {
        title: 'Candidates',
        url: '/candidates',
        icon: 'ListIcon',
        is_bookmarked: false,
      },
      {
        title: 'Recruiters',
        url: '/recruiters',
        icon: 'ListIcon',
        is_bookmarked: false,
      },
      {
        title: 'Teams',
        url: '/teams',
        icon: 'ListIcon',
        is_bookmarked: false,
      },
      {
        title: 'Users',
        url: '/users',
        icon: 'ListIcon',
        is_bookmarked: false,
      },
    ],
  },
};
/* eslint-enable */
