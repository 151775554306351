import axios from '@/http/axios/index';
import store from '@/store/store';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((cb) => cb(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function setAuthHeader(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}
setAuthHeader(localStorage.getItem('accessToken'));

export default {
  init() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (config.url === '/auth/login') {
          return Promise.reject(error);
        }

        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            store.dispatch('auth/fetchAccessToken').then(({ data }) => {
              const { access_token } = data;
              isAlreadyFetchingAccessToken = false;

              setAuthHeader(access_token);
              onAccessTokenFetched(access_token);
            });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
              resolve(axios(originalRequest));
            });
          });

          return retryOriginalRequest;
        }

        return Promise.reject(error);
      },
    );
  },

  login(email, password) {
    return axios.post('/auth/login', {
      email,
      password,
    });
  },
  registerUser(name, email, password, password_confirmation) {
    return axios.post('/auth/register', {
      name,
      email,
      password,
      password_confirmation,
    });
  },
  refreshToken() {
    return axios.post('/auth/refresh', {
      accessToken: localStorage.getItem('accessToken'),
    });
  },
  forgotPassword(email) {
    return axios.post('/auth/password/email', {
      email,
    });
  },
  resetPassword(token, email, password, password_confirmation) {
    return axios.post('/auth/password/reset', {
      token,
      email,
      password,
      password_confirmation,
    });
  },
};
