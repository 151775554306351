import Vue from 'vue';
import Vuex from 'vuex';

import state from '@/store/state';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import actions from '@/store/actions';

import moduleAuth from '@/store/auth/moduleAuth';
import moduleProfile from '@/store/profile/moduleProfile';

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    profile: moduleProfile,
  },
  strict: process.env.NODE_ENV !== 'production',
});
