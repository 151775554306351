import Vue from 'vue';

const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
};

const handleNofitication = (notify, type, title, text) => {
  const iconPack = 'feather';
  let color = NOTIFICATION_TYPE.SUCCESS;
  let icon = 'icon-check-circle';
  let time = 5000;

  switch (type) {
    case NOTIFICATION_TYPE.WARNING:
      color = NOTIFICATION_TYPE.WARNING;
      icon = 'icon-alert-triangle';
      time = 7000;
      break;
    case NOTIFICATION_TYPE.DANGER:
      color = NOTIFICATION_TYPE.DANGER;
      icon = 'icon-alert-circle';
      time = 7000;
      break;
    default:
  }

  notify({
    title,
    text,
    color,
    iconPack,
    icon,
    time,
  });
};

Vue.mixin({
  methods: {
    notifySuccess(title, text) {
      handleNofitication(this.$vs.notify, NOTIFICATION_TYPE.SUCCESS, title, text);
    },
    notifyWarning(title, text) {
      handleNofitication(this.$vs.notify, NOTIFICATION_TYPE.WARNING, title, text);
    },
    notifyError(error = null, title, text) {
      if (error) {
        console.error(error);
      }

      handleNofitication(this.$vs.notify, NOTIFICATION_TYPE.DANGER, title, text);
    },
  },
});
