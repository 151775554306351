import jwt from '@/http/requests/auth/jwt';
import axios from '@/axios';
import router from '@/router';

export default {
  login({ state }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        color: 'warning',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        time: 5000,
      });

      return false;
    }
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        name: payload.name,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = { ...payload.user.providerData[0] };
        newUserData.name = payload.name;
        commit('UPDATE_USER_INFO', newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/');
        }
      })
      .catch((error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time: 5000,
        });
      });
  },
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          // If there's user data in response
          if (response.data.userData) {
            // Set accessToken
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('tokenExpiry', Date.now() + response.data.expires_in * 1000);

            // Set permissions
            localStorage.setItem('permissions', JSON.stringify(response.data.userData.permission_names));

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, { root: true });

            // Set bearer token in axios
            commit('SET_BEARER', response.data.access_token);

            resolve(response);

            router.push(router.currentRoute.query.to || '/');
          } else {
            reject({ message: 'Wrong Email or Password' });
          }
        })
        .catch(reject);
    });
  },
  registerUserJWT({ commit }, payload) {
    const { name, email, password, password_confirmation } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== password_confirmation) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      return jwt
        .registerUser(name, email, password, password_confirmation)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/');

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.access_token);
          commit('UPDATE_USER_INFO', response.data.userData, { root: true });

          resolve(response);
        })
        .catch(reject);
    });
  },
  registerCandidate(context, payload) {
    const { password, password_confirmation, register_token } = payload;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== password_confirmation) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      return axios
        .post('/auth/register/candidate', {
          password,
          password_confirmation,
          register_token,
        })

        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  fetchAccessToken({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .refreshToken()
        .then((response) => {
          const { data } = response;
          const { access_token, expires_in, userData } = data;

          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('tokenExpiry', Date.now() + expires_in * 1000);

          commit('UPDATE_USER_INFO', userData, { root: true });

          resolve(response);
        })
        .catch(reject);
    });
  },
  recoverPassword(context, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPassword(payload.email)
        .then((response) => {
          // If there's message in response
          if (response.data.message) {
            resolve(response.data);
          } else {
            reject({
              message: 'Wrong Email or User with this email does not exists',
            });
          }
        })
        .catch(reject);
    });
  },
  resetPassword(context, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(payload.token, payload.email, payload.password, payload.password_confirmation)
        .then((response) => {
          if (response.data.message) {
            resolve(response.data);
          } else {
            reject({
              message: 'Problem with reset password',
            });
          }
        })
        .catch(reject);
    });
  },
  verifyEmail(context, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/auth/verify', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  resendVerificationCode(context, payload) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/auth/verify/resend', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  verifyManually(context, id) {
    return new Promise((resolve, reject) => {
      return axios.post(`/auth/${id}/verify-manually`).then(resolve).catch(reject);
    });
  },
};
