import Vue from 'vue';

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return '';
  value = value.toString();

  const arr = value.split(replacer);
  const capitalized_array = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });

  return capitalized_array.join(' ');
});

Vue.filter('percentage', (value) => {
  const val = Math.round((value * 100 + Number.EPSILON) * 100) / 100;
  return `${val}%`;
});

Vue.filter('datetime', (value) => {
  return new Date(value).toLocaleString();
});

Vue.filter('elapsedTime', (startTime) => {
  const x = new Date(startTime);
  const now = new Date();
  let timeDiff = (now - x) / 1000;

  const seconds = Math.round(timeDiff);
  timeDiff = Math.floor(timeDiff / 60);

  const minutes = Math.round(timeDiff % 60);
  timeDiff = Math.floor(timeDiff / 60);

  const hours = Math.round(timeDiff % 24);
  timeDiff = Math.floor(timeDiff / 24);

  const days = Math.round(timeDiff % 365);
  timeDiff = Math.floor(timeDiff / 365);

  const years = timeDiff;

  if (years > 0) return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`;
  if (days > 0) return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`;
  if (hours > 0) return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`;
  if (minutes > 0) return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`;
  if (seconds > 0) return seconds + (seconds > 1 ? ' sec ago' : 'just now');

  return 'Just Now';
});
