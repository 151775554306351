export const UserRole = Object.freeze({
  ALL: { label: 'All', value: 'all' },
  SYSADMIN: { label: 'SysAdmin', value: 'SYSADMIN' },
  ADMIN: { label: 'Admin', value: 'ADMIN' },
  TEAM_LEADER: { label: 'Team Leader', value: 'TEAM_LEADER' },
  RECRUITER: { label: 'Recruiter', value: 'RECRUITER' },
  CANDIDATE: { label: 'Candidate', value: 'CANDIDATE' },
});

export const userRoleList = [...Object.values(UserRole)];

export const getRoleByValue = (val = null) => {
  if (val === null) return '';

  return userRoleList.find((el) => el.value === val);
};

export const getRoleName = (val = null) => {
  if (val === null) return '';

  return getRoleByValue(val).label;
};
