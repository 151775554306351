export const getMessageFromError = ({ response, message }) => {
  if (!response) return;

  const { data } = response;
  const { errors } = data;
  const errorMessage = data.error || message;

  if (errors) {
    const [firstErrorKey] = Object.keys(errors);
    const [msg] = errors[firstErrorKey];

    return msg;
  }

  return errorMessage;
};

export const validObjectValues = (obj, lengthValidation = null) => {
  const results = [];

  if (typeof lengthValidation === 'number') {
    if (obj.length < lengthValidation) {
      return false;
    }
  }

  obj.forEach((element) => {
    const keys = Object.keys(element);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = element[key];
      const type = typeof value;

      if (value === null || value === undefined) {
        results.push(false);
        continue;
      }

      if (type === 'string') {
        results.push(value.trim() !== '');
        continue;
      }

      if (type === 'number') {
        results.push(value !== 0);
        continue;
      }
    }
  });

  return !results.includes(false);
};

export const disableLoader = () => {
  const appLoading = document.getElementById('loading-bg');

  if (appLoading) {
    appLoading.style.display = 'none';
  }
};

export const setPageTitle = (to) => {
  const title = process.env.VUE_APP_TITLE;
  const [titleEl] = document.getElementsByTagName('title');

  if (to.meta && to.meta.title) {
    titleEl.innerHTML = `${to.meta.title} | ${title}`;
  } else {
    titleEl.innerHTML = title;
  }
};
