class Gate {
  get user() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  get permissions() {
    return this.user.permission_names;
  }

  isRole(role) {
    return this.user.role === role;
  }

  can(permissions = []) {
    if (typeof permissions === 'string') {
      return this.permissions.includes(permissions);
    }

    if (!permissions.length) return true;

    return permissions.some((el) => this.permissions.includes(el));
  }
}

export default Gate;
