import axios from '@/axios';

const handleUploadPhoto = (method, resolve, reject, commit, file = null) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios[method]('/profile/photo', file, config)
    .then((response) => {
      commit('UPDATE_USER_INFO', response.data.userData, { root: true });
      resolve(response);
    })
    .catch(reject);
};

export default {
  changePassword(context, item) {
    return new Promise((resolve, reject) => {
      return axios.post('/profile/change-password', item).then(resolve).catch(reject);
    });
  },
  updateProfile({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put('/profile', item)
        .then((response) => {
          commit('UPDATE_USER_INFO', response.data.userData, { root: true });
          resolve(response);
        })
        .catch(reject);
    });
  },
  uploadPhoto({ commit }, photoFile) {
    return new Promise((resolve, reject) => {
      return handleUploadPhoto('post', resolve, reject, commit, photoFile);
    });
  },
  removePhoto({ commit }) {
    return new Promise((resolve, reject) => {
      return handleUploadPhoto('delete', resolve, reject, commit);
    });
  },
};
