import Vue from 'vue';
import Echo from 'laravel-echo';

window.io = require('socket.io-client');

const echoInstance = new Echo({
  broadcaster: 'socket.io',
  host: `${window.location.hostname}:6001`,
});

Vue.prototype.$echo = echoInstance;

export default Vue;
